<template>
  <div class="team">
    <Head :headdata="headdata"></Head>
    <div class="teamMain view common-main">

      <h2 class="title_zh">
        团队
        <div class="fenxBox">
          <img class="fenx" src="../assets/images/icon_fenx.png" alt />
          <p>分享</p>
          <share  />
        </div>
      </h2>
      <h2 class="title_en">Team</h2>
      
      <div class="teamHeadImg">
        <ul class="maux view flex-row flex-wrap ">
            <li class="imgList" v-for="(item,index) in headImgs" :key="index" @click="goLink(item)">
              <span class="imgBox">
              <img :src="item.img" alt />
              </span>
              <h5 class="projectTit">{{item.title}}</h5>
              <p>{{item.sub_title}}</p>
            </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/header.vue";
import share from "@/components/share.vue";
export default {
  data() {
    return {
      headdata: this.$t("m.companyHeaddata1"),
      headImgs: "",
    };
  },
  components: {
    Head,
    share
  },
  created() {
    this.getData();
  },
  updated() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  mounted() {},
  methods: {
    goLink(item) {
      this.$router.push('/teamInfo/'+item.id)
    },
    getData() {
      this.axios
        .get(this.GLOBAL.serverSrc + "/wc/members") // 真实环境中，后端会根据参数group返回新的图片数组，这里我用一个惊呆json文件模拟
        .then((res) => {
          console.log(res);
          if (res.data.code == 1) {
            this.headImgs = res.data.data;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.team {
  height: 100%;
  width: 100%;
  .maux {margin:0 -17px;margin-bottom:25px;}
  .teamMain {
    width: 100%;
    padding: 78px 200px 0 200px;
    .title_zh {
      color: #000;
      font-size: 34px;
      .fenxBox {
        float: right;
        cursor: pointer;
        .fenx {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 12px;
          color: #808080;
        }
      }
    }
    .title_en {
      margin-bottom: 60px;
      font-size: 24px;
    }
    .title_text {
      font-size: 18px;
      margin-bottom: 60px;
      color: #000;
    }
    .teamHeadImg {
      ul {

         .imgList:hover .projectTit {
            position: relative;
            display: inline-block;

          }
          .imgList:hover img{
            transform: scale(1.1);
            transition-duration:3s;
          }
         .projectTit {
            position: relative;
          }
          .projectTit:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0px;
            box-shadow: inset 0 -15px #0066b2;
            opacity: 0;
            transition: all ease .3s;
          }
          .imgList:hover .projectTit:after {
            height: 90%;
            opacity: .45;
          }
        li {
          display: inline-block;
          width: calc(( 100% - 102px ) / 3);
          margin-top: 0px !important;
          margin: 17px;
          cursor: pointer;
          .imgBox{
            display: block;
            width: 100%;
            overflow: hidden;
          }
          img {
            width: 100%;
            height: 100%;
          }
          p {
            font-size: 14px;
            color: #b3b3b3;
          }
         
          h5 {
            font-size: 18px;
            color: #000;
            line-height: 28px;
            margin-top: 10px;
            width:60px;
            text-align: left;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) { 
  
  .team {
    .teamHeadImg  {width: 100% !important;}
    .teamMain {
      
      
      .teamHeadImg {
         ul {
           
           li {
             width: 100vw !important;
             margin-bottom: 15px ;
           }
         }
      }
    } 
  } 
}
/deep/.p-l-20-p {padding-left: 200px !important;}
@media (max-width: 768px) {
  /deep/.p-l-20-p {padding-left: 0.5rem !important;}
}
</style>